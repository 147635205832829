/**
 * Get the default decision and respect essential group and legitimate interests.
 */
function getDefaultDecision(groups) {
  let respectLegitimateInterests = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  const essentialGroupObj = groups.find(_ref => {
    let {
      isEssential
    } = _ref;
    return isEssential;
  });

  // We need always use the current essential group
  const result = {
    [essentialGroupObj.id]: essentialGroupObj.items.map(_ref2 => {
      let {
        id
      } = _ref2;
      return id;
    })
  };
  if (respectLegitimateInterests) {
    for (const group of groups) {
      if (group === essentialGroupObj) {
        continue;
      }
      const ids = group.items.filter(_ref3 => {
        let {
          legalBasis
        } = _ref3;
        return legalBasis === "legitimate-interest";
      }).map(_ref4 => {
        let {
          id
        } = _ref4;
        return id;
      });
      if (ids.length) {
        result[group.id] = ids;
      }
    }
  }
  return result;
}
export { getDefaultDecision };