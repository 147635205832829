import { simpleObfuscate } from "../..";
function obfuscatePath(offset, str) {
  let mode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "keep-last-part";
  const result = str.split("/").map((part, i, arr) => {
    // Keep the last part of the full path to make it more "debuggable" in network tab of DevTools
    if (mode === "keep-last-part" && i === arr.length - 1) {
      return part;
    }
    return simpleObfuscate(part, offset, true);
  });

  // The first integer in the path-part determines the mode
  result.splice(result.length - 1, 0, `${mode === "full" ? 1 : 0}${offset.toString()}`);
  return result.join("/");
}
export { obfuscatePath };