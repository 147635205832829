import { CookieConsentManager } from "@devowl-wp/cookie-consent-web-client";
import { getCmpVersion, getOptionsFromWindow, getOtherOptionsFromWindow } from "../utils";
import { locationRestConsentPost } from "../wp-api";
import { commonRequest } from "@devowl-wp/utils";
import { applyConsentForwarding } from ".";
function getCookieConsentManager() {
  const {
    frontend: {
      decisionCookieName,
      groups,
      isGcm,
      revisionHash,
      setCookiesViaManager,
      failedConsentDocumentationHandling
    }
  } = getOtherOptionsFromWindow();
  if (!window.rcbConsentManager) {
    window.rcbConsentManager = new CookieConsentManager({
      // Access this way to avoid Proxy#get of `tcf`
      decisionCookieName,
      groups,
      isGcm,
      revisionHash,
      setCookiesViaManager,
      consentQueueLocalStorageName: "real_cookie_banner-consent-queue",
      supportsCookiesName: "real_cookie_banner-test",
      skipOptIn: function (cookie) {
        const {
          presetId
        } = cookie;
        return ["amazon-associates-widget"].indexOf(presetId) > -1;
      },
      cmpId: process.env.TCF_CMP_ID,
      cmpVersion: getCmpVersion("major"),
      failedConsentDocumentationHandling,
      persistConsent: async (transaction, setCookies) => {
        //throw new Error("test");
        const {
          restNamespace,
          restRoot,
          restQuery,
          restNonce,
          restPathObfuscateOffset
        } = getOptionsFromWindow();

        // Use `commonRequest` cause on frontend we do not have `wp.i18n` available
        const {
          forward,
          uuid
        } = await commonRequest({
          location: locationRestConsentPost,
          options: {
            restNamespace,
            restRoot,
            restQuery,
            restNonce,
            restPathObfuscateOffset
          },
          sendRestNonce: false,
          request: {
            ...transaction,
            setCookies
          },
          params: {
            _wp_http_referer: window.location.href
          }
        });
        if (forward) {
          applyConsentForwarding(forward);
        }
        return uuid;
      }
    });
  }
  return window.rcbConsentManager;
}
export { getCookieConsentManager };