import { pregQuote } from "@devowl-wp/react-utils";

/**
 * Create regular expression pattern from a string like `my_test*` and replace asterisks
 * with `(.*)`.
 */
function regExpPatternFromWildcardName(name) {
  const tmp = name.replace(/\*/g, "PLEACE_REPLACE_ME_AGAIN");
  return `^${pregQuote(tmp).replace(/PLEACE_REPLACE_ME_AGAIN/g, "(.*)")}$`;
}
export { regExpPatternFromWildcardName };