/**
 * This function allows to yield the main thread. In general, this is a polyfill for `scheduler.yield()`
 * which has not yet landed on all browsers. Use this to split a long running task into multiple tasks
 * to keep your application responsive.
 *
 * Hasn't `yield` in this context something to do with ES6 generator functions? No, as ES6 generator functions
 * to not yield the main thread.
 *
 * Example:
 *
 * ```ts
 * async function expensiveFunction() {
 *    const somethingExpensive1();
 *    const await yieldMainThread();
 *    const somethingExpensive2();
 * }
 * ```
 *
 * @see https://developer.chrome.com/blog/introducing-scheduler-yield-origin-trial
 */
// eslint-disable-next-line no-restricted-syntax
const yieldMainThread = resolveValue => new Promise(r => setTimeout(() => r(resolveValue), 0));
export { yieldMainThread };