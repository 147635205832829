import { applyDynamicsToHtml } from ".";
import { htmlDecode, yieldMainThread } from "@devowl-wp/react-utils";

/**
 * Put HTML code to the current DOM. `script` tags gets automatically
 * executed instead of pushing to DOM.
 *
 * @see https://github.com/krux/postscribe
 * @see https://stackoverflow.com/a/49724894/5506547
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function putHtmlCodeToDom(html, dynamics) {
  let referenceNode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : document.body;
  return new Promise(resolve => {
    if (html) {
      yieldMainThread().then(() => import( /* webpackChunkName: "banner-lazy", webpackMode: "lazy-once" */"postscribe").then(_ref => {
        let {
          default: postscribe
        } = _ref;
        return postscribe(referenceNode, applyDynamicsToHtml(html, dynamics), {
          done: resolve,
          error: e => {
            // We do ignore all errors as we handle like the usual browser
            // E.g. a script blocked by an ad-blocker should not break the execution
            // for upcoming scripts.
            console.error(e);
          },
          beforeWriteToken: token => {
            const {
              attrs,
              booleanAttrs,
              src,
              href
            } = token;

            // Probably skip this token? (do not use `disabled` tag as it should still be written to DOM)
            if (booleanAttrs !== null && booleanAttrs !== void 0 && booleanAttrs["skip-write"]) {
              return false;
            }

            // Automatically unescape html entities in all attributes (https://github.com/krux/postscribe/issues/346#issuecomment-310227387)
            for (const attr in attrs) {
              attrs[attr] = htmlDecode(attrs[attr]);

              // Allow to skip complete HTML tag when it already got loaded by unique ID (e.g. Google Tag Manager gtag.js)
              if (attr === "unique-write-name" && document.querySelector(`[unique-write-name="${attrs[attr]}"]`)) {
                return false;
              }
            }

            // Scripts
            if (src) {
              token.src = htmlDecode(src);
            }

            // Styles
            if (href) {
              token.href = htmlDecode(href);
            }
            return token;
          }
        });
      }));
    } else {
      resolve();
    }
  });
}
export { putHtmlCodeToDom };