import { useEffect, useRef } from "react";
const useEffectSkipFirst = (effect, deps) => {
  const observed = useRef(0);
  useEffect(() => {
    observed.current++;
    if (observed.current === 1) {
      return;
    }
    return effect();
  }, deps);
};
export { useEffectSkipFirst };